import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import useToken from '../../../hooks/useToken';
import authData from '../../../store/authData';
import authActions from '../../../store/authActions';
import AuthContext from '../../../context/AuthContext';
import { useLocation } from 'react-router-dom';

export const AuthProvider = ({ children }) => {
  const
    tokenTool = useToken(),
    locationHook = useLocation(),
    [authState, authDispatch] = useReducer(authActions, authData),
    resetAuthData = () => {
      authDispatch({
        type: 'updateState',
        payload: {
          ...authData,
          initialized: true
        }
      });
    },
    clearAuth = () => {
      tokenTool.destroy();
      resetAuthData();
    },
    checkExpirationDate = (expirationDateToCheck) => {
      if (expirationDateToCheck === null) {
        return;
      }
      const expirationDate = new Date(expirationDateToCheck);
      if (expirationDate < new Date()) {
        clearAuth();
      }
    },
    initialize = () => {
      const
        currentSession = tokenTool.get('hop-auth'),
        data = currentSession ? JSON.parse(currentSession) : null;
      if (!data) {
        authDispatch({
          type: 'updateState',
          payload: {
            initialized: true,
          }
        });
        return;
      }
      authDispatch({
        type: 'updateState',
        payload: {
          initialized: true,
          authTokenSession: data.authTokenSession ?? null,
          loaderRoutes: data.loaderRoutes ?? null,
          isSelectraAdmin: data.isSelectraAdmin ?? false,
          isExternal: data.isExternal ?? false,
          isMcpAgent: data.isMcpAgent ?? false,
          isAdmin: data.isAdmin ?? false,
          userId: data.userId ?? null,
          sessionExpiration: data.sessionExpiration ?? null
        }
      });
    };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    checkExpirationDate(authState.sessionExpiration);
  }, [locationHook.pathname, authState.sessionExpiration]);

  if (!authState.initialized) {
    return 'Loading session data...';
  }

  return (
    <AuthContext.Provider value={ { authState, authDispatch } }>
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};
