const authData = {
  initialized: false,
  authTokenSession: null,
  loaderRoutes: {
    'home': ''
  },
  isSelectraAdmin: false,
  isAdmin: false,
  isExternal: false,
  userId: null,
  sessionExpiration: null
};

export default authData;

